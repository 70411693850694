import { loadUser, loading } from "./helpers/login.api";
import { removeAuthHeader, setAuthHeader } from "./helpers/login.headers";
import { clearStorageValue, setStorageValue } from "./helpers/login.store";

const loggedIn = ref(false);
const loggedUser = ref<any>(null)
const loggedAccount = ref<any>(null);

export async function login(accessToken: string) {
    setAuthHeader(accessToken);

    const { user, account } = await loadUser();
    if (!user)
        return logout();

    setStorageValue('lat', accessToken);
    
    loggedUser.value = user;
    loggedAccount.value = account;
    loggedIn.value = true;
}

export function logout() {
    cleanSignin();

    loggedIn.value = false;
}

function cleanSignin() {
    removeAuthHeader();
    clearStorageValue('lat');
}

export function useLogin() { 

    return {
        login,
        logout,
        cleanSignin,
        loadUser,
    }
}


export { 
    loggedIn,
    loggedUser,
    loggedAccount,
    loading,
}
