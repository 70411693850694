function setStorageValue(name: string, value: string) {
    localStorage.setItem(name, value);
}

function getStorageValue(name: string) {
    return localStorage.getItem(name) || '';
}

function clearStorageValue(name: string) {
    return localStorage.removeItem(name);
}

export { 
    setStorageValue,
    getStorageValue,
    clearStorageValue
}