const errorsMessage: any = {
    'NotFoundBots': 'Texts:You are not connected to the chat bot',
    'NotFoundMissioner': 'Texts:You are not connected to the BibleSchool',
    'SigninTimeout': 'Texts:Timeout expired',
    'YouHasDeclinedSignin': 'Texts:You canceled your login',
    'AccessDenied': 'Texts:Access denied',
    'ConnectionError': 'Texts:Connection error',
    'SomeError': 'Texts:Error has occured'
}

function getError(code: string) {
    let message = errorsMessage[code];

    return { code, message };
}

function throwError(error: any) {
    throw getError(error.code);
}

export {
    throwError,
    getError
}