import { useAxios } from "@/composables/useAxios";
import { throwError } from "./login.errors"

const { axios, loading } = useAxios();

export async function loadUser(): Promise<any> {

    try {
        const { user, account } = await axios.get(`/auth/profile`) || {};
        return { user, account }
    }
    catch (e) {
        throwError({ code: 'ConnectionError' })
    }
}

export {
    loading
}