import { useAxios } from '@/composables/useAxios'

const { axiosGlobal } = useAxios();

function setAuthHeader(token: string) {
    const accessToken = token || localStorage.getItem('accessToken');
    const header = accessToken ? { Authorization: 'Bearer ' + accessToken } : {};

    Object.assign(axiosGlobal.defaults.headers.common, header);
}

function removeAuthHeader() {
    axiosGlobal.defaults.headers.common.Authorization = '';
}

function setLanguageHeader(language: string) {
    const header = language ? { 'User-Language': language } : {};

    Object.assign(axiosGlobal.defaults.headers.common, header);
}

function setServiceHeader(serviceId: string) {
    const header = serviceId ? { 'Service': serviceId } : {};

    Object.assign(axiosGlobal.defaults.headers.common, header);
}

function setCustomHeader(headerProp: any) {
    Object.assign(axiosGlobal.defaults.headers.common, headerProp);
}

export { 
    setAuthHeader,
    removeAuthHeader,
    setLanguageHeader,
    setServiceHeader,
    setCustomHeader
}